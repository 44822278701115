import React from 'react'
import Testimonial from '../Components/Cards/Testimonial/Testimonial'

function Testimonialpage() {
  return (

    <div>
      <Testimonial />
    </div>
  )
}

export default Testimonialpage
